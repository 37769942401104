.header {
    display: flex;
    justify-content: space-between;
    height: 100px;
}

.logoContainer {
    margin-top: 36px;
    margin-left: 63px;
}

.logoImg:hover {
    cursor: pointer;
}

.logoImg {
    height: 57px;
}

.userContainer:hover {
    cursor: pointer;
}

.userContainer {
    margin-top: 32px;
    margin-right: 50px;
    display: flex;
}

.userProfile {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid black;
    text-align: center;
    line-height: 50px;
    margin-right: 12px;
}

.userName {

}

.dropdown {
    border-radius: 8px;
    /*border: 1px solid rgb(223,223,223);*/
    background-color: none;
    height: 30px;
    width: 150px;
    display: flex;
    justify-content: start;
    margin-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    box-shadow: 0 0 8px rgb(0 0 0 / 0.2);
    z-index: 2;
}

.logoutButton:hover {
    cursor: pointer;
    background-color: rgb(221, 255, 225);
}

.logoutButton {
    font-family: 'Avenir';
    border: none;
    background-color: white;
    width: 100%;
    display:flex;
    justify-content: start;
    padding-left: 15px;
    align-items: center;
    z-index: 3;
}

.accountButton:hover {
    cursor: pointer;
    background-color: rgb(221, 255, 225);
}

.accountButton {
    font-family: 'Avenir';
    border: none;
    background-color: white;
    width: 100%;
    display:flex;
    justify-content: start;
    padding-left: 15px;
    align-items: center;
    z-index: 3;
}