@font-face {
    font-family: 'Avenir-Light';
    src: local('Avenir-Light'), url(./fonts/Avenir-Light.ttf) format('truetype');
}

.mainContainer {
    display:flex;
    justify-content: center;
    padding-bottom: 20px;
    min-height: 80.5vh;
}

.spinner {
    margin-top:250px;
    width: 150px;
    height: 150px;
}

.footer {
    color: gray;
    font-size: 10pt;
    margin-left: 60px;
    margin-bottom: 35px;
}

.cardHeader {
    font-family: "SF Pro Display", "Helvetica Neue", "Helvetica";
}

.centeredArea {
    width: 50%;
}

.setupRegion {
    border-radius: 10px;
    border: 1px solid rgb(223, 223, 223);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}



.activationRegion {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.codeInfo {
    font-family: "Avenir", "Avenir-Light";
    color: rgb(44, 44, 44);
}

.codeRegion {
    border-radius: 5px;
    border: 1px solid rgb(223, 223, 223);
    height: 35px;
    width: 250px;
    margin-left: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setupInput {
    width: 96%;
    height: 89%;
    padding-left: 10px;
    border: none;
    font-size: 11pt;
    border-radius: 2px;
}


.code {
    font-family: "Helvetica Neue";
    color: black;
    opacity: 0.3;
    margin-left:12px;
    letter-spacing: 2px;
}

.clipBoard {
    margin-right: 10px;
    width:20px;
    opacity: 0.3;
    margin-left: 15px;
}

.clipBoard:hover {
    opacity: 0.5;
    cursor: pointer;
}

.devicesConnected {
    flex: 1;
    height: 75px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.laptopDevice {
    width: 50px;
    margin-right: 5px;
}

.checkMark {
    color: rgb(0, 197, 69);
    margin-right:35px;
}

.integrationRegion {
    margin-bottom: 14px;
    border-radius: 10px;
    border: 1px solid rgb(223, 223, 223);
    width: 100%;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}

.addBilling:hover {
    cursor: pointer;
}

.addBilling {
    width: 120px;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: black;
    font-size: 11pt;
    padding: 11px;
    padding-bottom: 9px;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 8px;
    background-color: white;
}

.headerRegion {
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allReports {
    color: #10a37f;
    font-family: "Avenir", "Avenir-Light";
    font-size: 10pt;
    margin-right: 20px;
    margin-top: 2px;
}

.reportFlex {
    display:flex;
}

.onnumtext {
    font-family: "Avenir", "Avenir-Light";
    color: rgb(96, 96, 96);
    margin-top: 0px;
    margin-bottom: 0px;
}

.numspan {
    color: black;
    font-size: 20pt;
    margin-right: 2px;
}

.dashRegion {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.fixLinearSection {
    display:flex;
    align-items: center;
    width: 70%;
    padding-bottom: 20px;
}

.onnumtextL {
    color: black;
}

.linearInput {
    width: 175px;
    margin-right: 5px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 4px;
}

.linearButton:hover {
    cursor: pointer;
}

.linearCenter {
    width: 100%;
}

.fixItemFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top:10px;
}

.linearName {
    font-family: "Avenir", "Avenir-Light";
    color: rgb(96, 96, 96);
    margin: 0px;
}

.dropRegion {
    width: 100%;
}

.planText {
    font-size: 11pt;
}

.managerName {
    margin-top:0px;
    margin-bottom: 0px;
    margin-right: 5px;
    font-family: "Avenir", "Avenir-Light";
}
.managerItem {
    display:flex;
    padding-top:5px;
    padding-bottom:5px;
    justify-content: space-between;
}
.cardHeaderM {
    font-family: "Avenir", "Avenir-Light";
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 13pt;
}

.managerListFlex {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right:10px;
    min-width: 200px;
}

.addManagerSection {
    display:flex;
    justify-content: space-between;
    width: 70%;
    flex-wrap: wrap;
}

.addManagerInput {
    width: 200px;
    margin-right: 5px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 4px;
    height: 23px;
    padding-left: 8px;
}


.linearButton:hover {
    cursor: pointer;
}

.linearButton:disabled,
.linearButton[disabled] {
    opacity: 0.3;
    cursor: default;
}

.linearButton {
    background-color: white;
    border: 1px solid #891fb3;
    color: #891fb3;
    border-radius: 5px;
    height: 23px;
}

.addManagerButton:hover {
    cursor: pointer;
}

.addManagerButton:disabled,
.addManagerButton[disabled] {
    opacity: 0.3;
    cursor: default;
}

.addManagerButton {
    background-color: white;
    border: 1px solid #10a37f;
    color: #10a37f;
    border-radius: 5px;
    height: 25px;
}

.removeManagerButton:hover {
    cursor: pointer;
}


.removeManagerButton {
    font-size: 9pt;
    height: 25px;
    background-color: white;
    border: 1px solid rgb(169, 24, 24);
    color: rgb(169, 24, 24);
    border-radius: 5px;
}


.sectionHeader {
    display:flex;
    justify-content: space-between;
    align-items: center;
}


.macFlex {
    display: flex;
    align-items: center;
    justify-content: end;
}

.macDownload {
    width:30px;
    height:30px;
}

.downloadIcon {
    color: gray;
    margin-top:3px;
    margin-right: 5px;
    font-size: 10pt;
}

.downloadText {
    font-family: "SF Pro";
    font-size: 11pt;
    color: gray;
    margin-top: 4px;
    margin-bottom: 0px;
    margin-right: 10px;
}


.dashCard {
    margin-top:9px;
    margin-bottom:9px;
    width: 93%;
    background-color: white;
    border-radius: 10px;
   
    display: flex;
    align-items: center;
}

.endPad {
    height: 10px;
    width: 100%;
}

.integrationCard {
    margin-top:9px;
    margin-bottom:9px;
    width: 97%;
    height: 100px;
    background-color: white;
    border-radius: 10px;
   
    display: flex;
    align-items: center;
}

.teaminfodiv{
    width: 90%;
    padding-top: 10px;
    padding-bottom:10px;
}

.teamDiv{
    width: 90%;
    padding-top: 15px;
    padding-bottom:15px;
}

.teamCard {
    width: 100%;
    height: 75px;
    background-color: white;
    border-radius: 10px;
   
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.github-icon {
    margin-right: 7px;
}

.integrationLogo {
    height:57px;
    width:57px;
    margin-left: 20px;
}

.integrationLogoSmall {
    height:53px;
    width:53px;
    margin-left: 20px;
    border: 2px solid transparent;
}

.integrationInfo {
    height:60px;
    flex-grow:5;
    margin-left: 20px;
}

.integrationConnect {
    height:60px;
    flex-grow:1;
    margin-left: 15px;
    margin-right: 20px;
    display:flex;
    justify-content: end;
    align-items: center;
}

.infoHeader {
    font-family: "Avenir", "Avenir-Light";
    margin-top: 5px;
    margin-bottom: 5px;
}

.infoFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.infoSub {
    font-family: "Avenir", "Avenir-Light";
    color: gray;
    margin-top: 0px;
    margin-bottom: 0px;
}

.infoCircle {
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 10pt;
}

.integrationButton:hover {
    cursor: pointer;
}

.integrationButton {
    width: 100px;
    background-color: black;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: white;
    font-size: 11pt;
    height: 40px;
    border: none;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
}

.onboardMembers:hover{
    cursor: pointer;
}

.onboardMembers{
    width: 155px;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: black;
    font-size: 11pt;
    padding: 11px;
    padding-bottom: 9px;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 8px;
    background-color: white;
}

.linkButton:hover{
    cursor: pointer;
}

.linkButton{
    width: 130px;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: black;
    font-size: 11pt;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid black;
    border-radius: 8px;
    margin-right: 8px;
    background-color: white;
    margin-top:15px;
    margin-bottom:15px;
}

.startButton:hover {
    cursor: pointer;
}

.startButton {
    width: 105px;
    background-color: white;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: #10a37f;
    font-size: 11pt;
    height: 40px;
    border: 1px solid #10a37f;
    border-radius: 8px;
    text-align: center;
    line-height: 39px;
    text-decoration: none;
}

.deleteButton:hover {
    cursor: pointer;
}

.deleteButton {
    width: 105px;
    background-color: white;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: rgb(207, 61, 61);
    font-size: 11pt;
    height: 40px;
    border: 1px solid rgb(207, 61, 61);
    border-radius: 8px;
    text-align: center;
    line-height: 39px;
    text-decoration: none;
}

.billingButton:hover {
    cursor: pointer;
}

.billingButton {
    width: 105px;
    background-color: white;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: rgb(160, 62, 193);
    font-size: 11pt;
    height: 40px;
    border: 1px solid rgb(160, 62, 193);
    border-radius: 8px;
    text-align: center;
    line-height: 39px;
    text-decoration: none;
}


.doneButton:hover {
    cursor: pointer;
}

.doneButton {
    width: 100px;
    background-color: #10a37f;
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: white;
    font-size: 11pt;
    height: 40px;
    border: 1px solid #10a37f;
    border-radius: 8px;
    text-align: center;
    line-height: 39px;
    text-decoration: none;
}


.checkComplete {
    color: #10a37f;
    font-size: 15pt;
}

.startRegion {
    display:flex;
    justify-content: end;
    margin-top: -40px;
}

.doneRegion {
    width: 100%;
    display:flex;
    justify-content: end;
    padding-bottom: 20px;
    padding-top: 10px;
}

.required {
    color: red;
    margin-left: 5px;
    margin-top: -3px;
}

.upgradeButton:hover {
    cursor: pointer;
}

.upgradeButton {
    background-color:white;
    border: none;
    color:#10a37f;
    font-size: 10pt;
    border-radius: 8px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 3px;
    font-weight: 400;
}

.connectedButton {
    width: 120px;
    background-color: rgb(0, 186, 90);
    font-family: "Avenir", "Avenir-Light";
    font-weight: 800;
    color: white;
    font-size: 11pt;
    height: 40px;
    border: none;
    border-radius: 8px;
}

.integrationDivider {
    width: 100%;
    height: 1px;
    background-color: rgb(223, 223, 223);
}

.dis {
    background-color: gray;
}

.questionDiv {
    width: 92.5%;
    padding-top: 25px;
    padding-bottom: 25px;
}


.activePill {
    background-color:#d2f4d3;
    font-family: "Helvetica";
    font-size: 9pt;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    color: #1a7f64;
    border-radius: 5px;
    font-weight: bold;
}