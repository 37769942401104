@font-face {
    font-family: 'Avenir-Light';
    src: local('Avenir-Light'), url(./fonts/Avenir-Light.ttf) format('truetype');
}

.loginContainer {
    display: flex;
    justify-content: center;
}


.loginRegion {
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgb(223, 223, 223);
    width: 100%;
    display: flex;
    justify-content: center;
}

.centeredAreaLogin {
    width: 35%;
}


.textInfo {
    font-family: "Avenir", "Avenir-Light";
    color: gray;
}

.eye {
    width: 20px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: -30px;
    position:inline-block;
}

.forgot {
    color: gray;
    font-size: 9pt;
    margin: 0px;
    background-color: transparent;
    border: none;
}

.forgot:hover {
    cursor: pointer;
}

.signreg {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 13px;
    flex-wrap: wrap;
}

input {
    font-family: "Avenir", "Avenir-Light";
}

.regflex{
    display:flex;
}

.noaccount {
    color: gray;
    font-size: 10pt;
    margin: 0px;
}

.reglog {
    background-color: transparent;
    border: none;
    color: #10a37f;
    font-weight: 500;
    font-size: 10pt;
}

.reglog:hover {
    cursor: pointer;
}


.orText {
    font-family: "Avenir", "Avenir-Light";
    color: gray;
    font-size: 10pt;
}


.loginInterior {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 65%;
}

.loginInputs {
    width: 100%;
}

.textRegion{
    border-radius: 5px;
    border: 1px solid rgb(223, 223, 223);
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-family: "Avenir";
}

.signInBlock {
    display:flex;
    justify-content: start;
}

.googleHolder {
    display:flex;
    justify-content: start;
    margin-top: 10px;
}

.textInput {
    font-family: "Helvetica Neue";
    color: black;
    opacity: 0.3;
    margin-left:12px;
    letter-spacing: 2px;
}

.signInButton:hover {
    cursor: pointer;
}

.signInButton{
    width: 100px;
    background-color: black;
    font-family: "Avenir";
    font-weight: 800;
    color: white;
    font-size: 11pt;
    height: 40px;
    border: none;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
}


.googleButton:hover {
    cursor: pointer;
}

.googleButton {
    width: 100%;
    background-color: black;
    font-family: "Avenir";
    font-weight: 800;
    color: white;
    font-size: 11pt;
    height: 40px;
    border: none;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
}

.gIcon {
    font-size: 13pt;
    margin-right: 5px;
}

.orDivider {
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.lineDiv {
    background-color: rgb(223, 223, 223);
    width:44%;
    height:1px;
}